import React from 'react';
import { Link } from 'react-router-dom';

import Switch from './Switch';
import './Header.scss';

const Header = () => {
  const toggleSideBar = () => {
    const siteNav = document.getElementsByClassName('site-nav')[0]
    siteNav.classList.add('animate');
    siteNav.classList.toggle('mobile-menu-hide')
  }

  return (
    <header id="site_header" className="header">
      <div className="header-content clearfix">
        <div className="text-logo">
          <a href="/">
            <div className="logo-symbol">N</div>
            <div className="logo-text">uno <span>Brites</span></div>
          </a>
        </div>
        <div className="site-nav mobile-menu-hide">
          <ul id="menu-classic-menu" className="leven-classic-menu site-main-menu">
            <li className="menu-item">
              <Link to="/home" aria-current="page" data-hover="1" onClick={() => toggleSideBar()}>Home</Link>
            </li>
            <li className="menu-item">
              <Link to="/resume" aria-current="page" data-hover="1" onClick={() => toggleSideBar()}>Resume</Link>
            </li>
            <li className="menu-item">
              <Link to="/blog" aria-current="page" data-hover="1" onClick={() => toggleSideBar()}>Blog</Link>
            </li>
            <li className="menu-item menu-item-separator">|</li>
            <li className="menu-item menu-item-dark-mode">
              <Switch />
            </li>
          </ul>
        </div>
        <span className="menu-toggle mobile-visible" onClick={() => toggleSideBar()}>
          <i className="fa fa-bars"></i>
        </span>
      </div>
    </header>
  );
}

export default Header;